<template>
  <img src="./assets/log_logo_wander@3x.png" style="width: 80%;" tabindex="-1" >
  <RegisterForm  />
</template>

<script>
import RegisterForm  from './components/RegisterForm.vue'

export default {
  name: 'App',
  components: {
    RegisterForm
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
