<template>
  <form class="register-form" @submit.prevent="submitForm">
    <div class="form-group">
      <div class="phone-input">
        <Vue3CountryIntl
            schema="popover"
            elId="my_reference"
            v-model="schemaPopoverData.default"
            v-model:visible="schemaPopoverData.visible">
          <button style="margin-left: 0" type="button" @click="schemaPopoverData.visible = true" v-text="schemaPopoverData.default"></button>
        </Vue3CountryIntl>
        <input style="width:calc(100% - 150px);margin-left: 10px " placeholder="请输入手机号" type="text" id="phone" v-model="formData.phoneNumber" required>
      </div>
    </div>

    <div class="form-group">
      <div class="sms-code-input">
        <input placeholder="验证码" style="width:calc(100% - 150px) " type="text" id="smsCode" v-model="formData.smsCode" required>
        <button type="button" :disabled="smsButtonDisabled" @click.stop="generateSmsCode" aria-label="验证码">
          {{ smsButtonText }}
        </button>
      </div>
    </div>

    <div class="form-group">
      <input placeholder="请输入密码" type="password" id="password" v-model="formData.password" required>
    </div>

    <div class="form-group">
      <input placeholder="请确认密码" type="password" id="confirmPassword" v-model="formData.confirmPassword" required>
    </div>

<!--    <button type="submit" style="width: 60%">注册</button>-->

    <button type="submit" style="width: 60%" :disabled="loading">
      <span v-if="loading">等待中...</span>
      <span v-else>注册</span>
    </button>
  </form>

  <div>
    <!-- 其他内容 -->
    <AlertBox :show="showAlert" :message="alertMessage" :type="alertType" @close="closeAlert" />
  </div>
</template>

<script>

import AlertBox from './AlertBox.vue';
import Vue3CountryIntl from 'vue3-country-intl';
// 引入css
import 'vue3-country-intl/lib/vue3-country-intl.css'

import axios from 'axios';
import {onMounted, ref} from "vue";



export default {
  components: {
    AlertBox,
    Vue3CountryIntl
  },

  setup(){
    const referrerID = ref(''); // 创建一个响应式的 ref 变量

    let loading = ref(false);
    let schemaPopoverData= ref({
      default:'65',
      visible:false,
    });
    let formData ={
      phoneNumber: '',
      smsCode: '',
      password: '',
      confirmPassword: ''
    };
    let countryCode = '+65';
    let smsButtonDisabled= false;
    let smsButtonText= ref('验证码');
    let timer = null
    let timerSeconds = ref(60);
    let showAlert = ref(false);
    let alertMessage = ref('');
    let alertType = 'info' // 可以是 'success', 'error', 'warning' 等

    onMounted(() => {
      // 通过 URLSearchParams 解析参数
      let params = new URLSearchParams(window.location.search);
      // 获取特定参数值
      referrerID.value = params.get('referrerID');

      console.log('传入参数',referrerID.value);
      // 可以在这里获取 URL 参数等操作
    });
    function  checkPasswordsMatch() {
      return formData.password === formData.confirmPassword;
    }
    async function submitForm() {


      if (loading.value) {
        return; // 如果正在加载，防止重复提交
      }
      if (!checkPasswordsMatch()) {
        showAlertBox("两次密码不一致", "error");
        return;
      }

      loading.value = true; // 开始加载
      console.log('传入参数', referrerID.value)

      try {
        const response = await axios.post('https://lovechat.wanderwoo.life/api/login/registerForWeb', {
          mobile: schemaPopoverData.value.default + formData.phoneNumber,
          smscode: formData.smsCode,
          password: formData.password,
          referrerID: referrerID.value,
          // 可能需要其他参数
        });
        if(response.data['code'] == 200){
          showAlertBox("注册成功", "info");
          // 跳转到另一个 URL
          window.location.href = 'https://wanderwoo.life/';
        }else {
          showAlertBox(response.data['msg'],'error');
        }

        // 根据后端 API 返回的响应处理结果
        console.log('注册成功:', response.data);
        // 可能有其他处理逻辑
      } catch (error) {
        // 处理请求失败的情况
        showAlertBox("注册失败，请重试", "error");
        // 可能有其他处理逻辑
      }finally {
        loading.value = false; // 结束加载
      }

    }
    // 用于显示提示框的方法
    function showAlertBox(message, type) {
      alertMessage.value = message;
      alertType = type;
      showAlert.value = true;
      // 阻止页面滚动
      document.body.style.overflow = 'hidden';

      // 自动隐藏提示框（假设3秒后自动隐藏）
      setTimeout(() => {
        closeAlert();
      }, 3000);
    }
    // 用于关闭提示框的方法
    function closeAlert() {
      showAlert.value = false;
      document.body.style.overflow = ''; // 恢复页面滚动
    }

    function  startTimer(){
      timer = setInterval(() => {

        if (timerSeconds.value > 0) {
          timerSeconds.value--;
          smsButtonText.value = `${timerSeconds.value}秒`;
        } else {
          clearInterval(timer);
          smsButtonDisabled = false;
          timerSeconds.value = 60;
          smsButtonText.value = '验证码';
        }
      }, 1000);
    }

    async function generateSmsCode() {
      console.log('生成验证码');
      smsButtonDisabled = true;
      startTimer();
      try {
        const response = await axios.post('https://lovechat.wanderwoo.life/api/login/smscode', {
          account: schemaPopoverData.value.default + formData.phoneNumber,
          type:'mobile',
          // 可能需要其他参数
        });
        // 根据后端 API 返回的响应处理结果
        console.log('短信验证码发送成功:', response.data);
        // 可能有其他处理逻辑
      } catch (error) {
        // 处理请求失败的情况
        console.error('短信验证码发送失败:', error.response.data);
        // 可能有其他处理逻辑
      }
    }

    return{
      referrerID,
      submitForm,
      schemaPopoverData,
      formData,
      countryCode,
      smsButtonDisabled,
      smsButtonText,
      timer,
      timerSeconds,
      showAlert,
      alertMessage,
      alertType,
      loading,
      generateSmsCode,
      closeAlert

    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
};
</script>

<style scoped>
/* 样式可根据需要进行更改 */
.register-form {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
}

input[type="text"],
input[type="password"],
input[type="number"] {
  width: calc(100% - 80px);
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.sms-code-input {
  //display: flex;
  align-items: center;
}
.phone-input {
  //display: flex;
  align-items: center;
}

button {
  width: 60px;
  padding: 8px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

button:hover {
  background-color: #0056b3;
  color: #fff;
}
</style>
