<template>
  <div v-if="show" class="alert-overlay">
    <div class="alert-box" :class="alertType">
      <span>{{ message }}</span>
      <button @click="hideAlert">&times;</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'info' // 可以是 'success', 'error', 'warning' 等
    }
  },
  methods: {
    hideAlert() {
      this.$emit('close');
    }
  },
  computed: {
    alertType() {
      return `alert-${this.type}`;
    }
  }
};
</script>

<style scoped>

.alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-box {
  position: relative;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.alert-info {
  background-color: #cce5ff;
  color: #004085;
  border: 1px solid #b8daff;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.alert-warning {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

.alert-box span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.alert-box button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: inherit;
}
</style>
